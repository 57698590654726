import { useState } from 'react';
import '../newIndex.css';
import NumberedTitle from '../components/NumberedTitle';

function NewIndex() {
    var [menuOpen, setMenuOpen] = useState(true);

    function handleClick() {
        console.log("hello" + menuOpen)
        setMenuOpen(!menuOpen);
    }

    return (
        <div className='main home'>
            <a className='skip-to-content' href="#main">Skip to content</a>
            <header className='primary-header flex'>
                <div>
                    {/* put a logo here */}
                </div>
                <button 
                    className={`mobile-nav-toggle ${menuOpen ? "display-open-button" : "display-close-button"}`}
                    aria-controls="primary-navigation" 
                    aria-expanded="false"
                    onClick={handleClick}
                >
                    <span className='sr-only'>Menu</span>
                </button>
                <nav>
                    <ul id="primary-navigation" className={`primary-navigation underline-indicators flex ${menuOpen ? "menu-open" : ""}`}>
                        <NumberedTitle isActive={true} number="0" text="Home" url="/"/>
                        <NumberedTitle number="1" text="About" url="#"/>
                        <NumberedTitle number="2" text="Projects" url="#"/>
                    </ul>
                </nav>
            </header>
            <main id="main" className='grid-container grid-container--home'>
                {/* <div>
                    <div>
                        <a href="#" className='large-button uppercase ff-serif text-dark bg-white'>Hello</a>
                    </div>
                </div> */}
                <div className='direction-rtl'>
                    <h1 className='text-accent fs-500 ff-sans-cond uppercase letter-spacing-1'>
                        Developer, Board Gamer, Cyclist, Legend*&lrm;
                        <span className='fs-900 ff-serif text-dark d-block'>jonnus&lrm;</span>
                    </h1>
                    <p className='text-accent'>
                        A breif portfolio of stuff I've worked on, technologies I use, and some of my other interests.&lrm;
                    </p>                

                    <span className='text-accent fs-200 ff-sans-cond'>
                        <span>&lrm;</span>
                        * Legendary status still pending official certification.&lrm;
                    </span>
                </div>
                
            </main>
        </div>
    )
}

export default NewIndex