function ProjectCard(props) {
    return (
        <div className="card">
            <img className="card--image" src={`./images/${props.item.coverImg}`} />
            <h3 className="card--summary"><a href={props.item.url}>{props.item.title} ↪ </a></h3>
            <p className="card--description">{props.item.description}</p>
        </div>
    )
}

export default ProjectCard;