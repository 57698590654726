import '../App.css';
import ProjectCard from '../components/ProjectCard';
import TechSkill from '../components/TechSkill';
import WorkExperience from '../components/WorkExperience';
import projectData from '../projectData';
import techSkillsData from '../techSkillsData';
import workExperienceData from '../workExperienceData';

function Index() {

const projects = projectData.map(item => {
  return (
    <ProjectCard
      key={item.id}
      item={item}
    />
  )
})

const workExperience = workExperienceData.map(item => {
  return (
    <WorkExperience
      key={item.id}
      item={item}
    />
  )
})

const techSkills = techSkillsData.map(item => {
  return (
    <TechSkill
      key={item.id}
      item={item}
    />
  )
})

const mainStyles = {
  maxWidth: "1400px"
}

const avatarStyles ={
  width: "100%"
}

const style100={
  width: "100%"
}

const style75={
  width: "75%"
}

const styleh100={
  height: "24px",
  width: "100%"
}

const styleh75={
  height: "24px",
  width: "75%"
}

  return (
    <main>
      <div className="content margin-top" style={mainStyles}>

        {/* The GRid */}
        <div className="row-padding">
          {/* Left Column */}
          <div className="third">
            <div className="white amaranth-text card-4">
              <div className="display-container">
                <img src="/images/IMG_20220828_124159_Bokeh.jpg" style={avatarStyles} alt="Avatar" />
                <div className="display-bottomleft container amaranth-text">
                  <h2><b>Jon Lewis</b></h2>
                </div>
              </div>
            </div>
            <div className="container">
              <p className="light-blue-text">💼 Full Stack Software Developer</p>
              <p className="light-blue-text">🌐 Dorchester, UK</p>
              <p className="light-blue-text">📧 contact@jonnus.co.uk</p>
              <hr/>

              <p className="large light-blue-text"><b>💡 Skills</b></p>
              {techSkills}
              <br/>
            </div>
          </div>
          {/* Right Column */}
          <div className="twothird">
            <div className="container card white margin-bottom">
              <h2 className="amaranth-text padding-16">🏹 Projects</h2>
              {projects}
            </div>

            <div className="container card white margin-bottom">
              <h2 className="amaranth-text padding-16">🤸 About Me</h2>
              <div className="container">
                <h5 className="opacity xford-blue-text padding-16"><b>Work</b></h5>
                <p>My particular interests lie in improving quality of software delivery, promoting the adoption and use of Unit Tests and other forms of automated testing. In an ideal world, I believe manual testing of applications should be a last resort.</p>
                <p>I am also an advocate of the Agile development process. Through rapid iterations delivering incremental improvements with frequent customer feedback, delivery of value to the end user can be achieved in an optimal way. An integrated, highly communicative team working towards a common goal is a recipe for success.</p>
                <p>I have previously held the role of SCRUM Master and been part of an Agile team who were developing a Dashboard for Project Managers incorporating information for a disparate number of external applications, using SSIS, SSAS, and SSRS.</p>
              </div>
              <div className="container">
                <h5 className="opacity oxford-blue-text padding-16"><b>Home</b></h5>
                <p>I am a father to two (rapidly growing) children who enjoy nothing more than eating, gluing, sticking, and making other forms of mess.</p>
                <p>Since relocating to Dorset, I have been trying to take advantage of the great outdoors. Be that by bike, hiking in the countryside, or simply going to the beach and building sandcastles.</p>
                <p>I organise Dorchester Board Games, an informal group meeting weekly at a local pub to play a variety of modern board and card games.</p>
              </div>
            </div>

            <div className="container card white margin-bottom">
              <h2 className="amaranth-text padding-16">💼 Work Experience</h2>
              {workExperience}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

    
      {/* <section>
        <h1>Hello</h1>
      </section>
      <section>
        <h2>Projects</h2>
        <div className="cards-list">
          {projects}
        </div>
      </section>
      <section>
        <h2>
          Work
        </h2>
        <div>
          <p>I am a Senior Software Enginner for Tessian, an email security company.  I work in the .net team supporting the business with the logic required to run the rules that detect is an email is malicious or not.</p>
          <p>Previously, I worked for Morning Data, based in the Dorchester office.  I was responsible for the maintenance and improvement of our primary Novus application.  In addition, I was responsible for creating our new RESTful API, created in .net Core 3.1.</p>
          <p>Before that, I worked for Mouser Electronics, as a Lead Web Developer for the Internet Business team. I was on the team reworking this legacy website to take advantage of the benefits we can gain from applying MVC to this solution.</p>
          <p>My particular interests lie in improving quality of software delivery, promoting the adoption and use of Unit Tests and other forms of automated testing. In an ideal world, I believe manual testing of applications should be a last resort.</p>
          <p>I am also an advocate of the Agile development process. Through rapid iterations delivering incremental improvements with frequent customer feedback, delivery of value to the end user can be achieved in an optimal way. An integrated, highly communicative team working towards a common goal is a recipe for success.</p>
          <p>Prior to this, I was a Full-Stack Applications Developer for a FTSE 100 company, working on Line of Business applications. These were primarily coded in C#.NET. There were also a number of legacy applications written in VB6 that I was in the process of re-hosting in ASP.NET MVC5 and using Entity Framework 6.</p>
          <p>I have previously held the role of SCRUM Master and been part of an Agile team who were developing a Dashboard for Project Managers incorporating information for a disparate number of external applications, using SSIS, SSAS, and SSRS.</p>
        </div>
      </section>
      <section>
        <h2>Home</h2>
        <div>
          <p>I am a father to two (rapidly growing) children who enjoy nothing more than eating, gluing, sticking, and making other forms of mess.</p>
          <p>Since relocating to Dorset, I have been trying to take advantage of the great outdoors. Be that by bike, hiking in the countryside, or simply going to the beach and building sandcastles.</p>
          <p>I organise Dorchester Board Games, an informal group meeting weekly at a local pub to play a variety of modern board and card games.</p>
        </div>
      </section>
      <section>
        <h2>
          Contact
        </h2>
        <div>
          <p>You can contact me at <strong><a href="mailto:contact@jonnus.co.uk">contact@jonnus.co.uk</a></strong>
          </p>
        </div>
      </section> */} 

export default Index;