export default [
    {
        id: 1,
        jobTitle: "Senior Software Engineer",
        company: "Tessian",
        dateRange: "Sep 2022 - Present",
        description: "I work in the .net team supporting the business with the logic required to run the rules that detect is an email is malicious or not.",
    },
    {
        id: 2,
        jobTitle: "Senior Applications Developer",
        company: "Morning Data Ltd",
        dateRange: "Apr 2020 - Sep 2022",
        description: "I was responsible for the maintenance and improvement of our primary Novus application.  In addition, I was responsible for creating our new RESTful API, created in .net Core 3.1.",
    },
    {
        id: 3,
        jobTitle: "Lead Applications Developer",
        company: "Mouser",
        dateRange: "Oct 2017 - Apr 2020",
        description: "I was on the team reworking this legacy website to take advantage of the benefits we can gain from applying MVC to this solution.",
    },
    {
        id: 4,
        jobTitle: "Software Engineer",
        company: "BAE Systems",
        dateRange: "Sep 2007 - Oct 2017",
        description: "Prior to this, I was a Full-Stack Applications Developer for a FTSE 100 company, working on Line of Business applications. These were primarily coded in C#.NET. There were also a number of legacy applications written in VB6 that I was in the process of re-hosting in ASP.NET MVC5 and using Entity Framework 6."
    }
]