export default [
    {
        id: 3,
        title: "MyEnergy",
        description: "Query how much energy I've either pulled from the grid, or exported for cash",
        coverImg: "myEnergy.png",
        url: "https://bespoke-vacherin-23a74a.netlify.app"
    },
    {
        id: 1,
        title: "Dorchester Board Games",
        description: "Website to host details of our weekly Board Games meet ups",
        coverImg: "dbg.png",
        url: "https://www.dorchesterboardgames.co.uk/"
    },
    {
        id: 2,
        title: "Tenzies",
        description: "Get 10 matching dice.  Can you beat your high score?",
        coverImg: "tenzies.png",
        url: "https://cosmic-stroopwafel-0b439b.netlify.app/"
    },
]