function TechSkill(props) {
    const styles={
        width: `${props.item.proficiency}%`
      }

    return (
        <>
            <p className="light-blue-text">{props.item.skill}</p>
            <div className="light-grey round-xlarge small">
                <div className="container center round-xlarge teal" style={styles}>{props.item.proficiency}%</div>
            </div>
        </>
    )
}

export default TechSkill;