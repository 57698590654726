export default [
    {
        id: "asdasd",
        skill: "C#",
        proficiency: 95
    },
    {
        id: "asd",
        skill: "React",
        proficiency: 50
    },
]