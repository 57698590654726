function WorkExperience(props) {
    return (
        <div className="container">
            <h5 className="opacity oxford-blue-text"><b>{props.item.jobTitle} / {props.item.company}</b></h5>
            <h6 className="sky-blue-text">📅 {props.item.dateRange}</h6>
            <p>{props.item.description}</p>
            <hr/>
        </div>
    );
}

export default WorkExperience;